@font-face {
    font-family: PxPlusChipPlayer;
    src: url(pxplus_chipplayer.ttf) format('truetype');
}

:root { --fontPxPlusChipPlayer: 24px/19px PxPlusChipPlayer; }

@font-face {
    src: url(caveras/Arcology.woff) format('woff');
    font-family: Arcology;
}

:root { --fontArcology: 8px Arcology; }

@font-face {
    src: url(caveras/ChronoType.woff) format('woff');
    font-family: ChronoType;
}

:root { --fontChronoType: 16px ChronoType; }

@font-face {
    src: url(caveras/Ishmeria.woff) format('woff');
    font-family: Ishmeria;
}

:root { --fontIshmeria: 16px Ishmeria; }

@font-face {
    src: url(caveras/MMRock9.woff) format('woff');
    font-family: MMRock9;
}

:root { --fontMMRock9: 8px MMRock9; }

@font-face {
    src: url(caveras/NDS12.woff) format('woff');
    font-family: NDS12;
}

:root { --fontNDS12: 10px NDS12; }

@font-face {
    src: url(caveras/Quarlow.woff) format('woff');
    font-family: Quarlow;
}

:root { --fontQuarlow: 16px Quarlow; }

@font-face {
    src: url(caveras/Reactor7.woff) format('woff');
    font-family: Reactor7;
}

:root { --fontReactor7: 16px Reactor7; }

@font-face {
    src: url(int10h/WebPlus_ToshibaSat_9x14.woff) format('woff');
    font-family: Stingray;
}

:root { --fontStingray: 14px/16px Stingray; }

@font-face {
    src: url(mm-tahoma-8px.ttf) format('truetype');
    font-family: TahomaPixel;
}

:root { --fontTahomaPixel: 16px/16px TahomaPixel, Tahoma; }

@font-face {
    src: url(Tahoma.woff) format('woff');
    font-family: Tahoma;
}

:root { --fontTahoma: 11px/16px Tahoma, Arial, sans-serif; }

@font-face {
    src: url(pentacom/DeckardsRegular.ttf) format('truetype');
    font-family: DeckardsRegular;
}

:root { --fontDeckardsRegular: 16px DeckardsRegular; }

@font-face {
    src: url(pentacom/FutureKid.ttf) format('truetype');
    font-family: FutureKid;
}

:root { --fontFutureKid: 16px FutureKid; }

@font-face {
    src: url(pentacom/ldcBlackRound.ttf) format('truetype');
    font-family: ldcBlackRound;
}

:root { --fontldcBlackRound: 16px ldcBlackRound; }

@font-face {
    src: url(pentacom/RobotoBitmap.ttf) format('truetype');
    font-family: RobotoBitmap;
}

:root { --fontRobotoBitmap: 16px RobotoBitmap; }

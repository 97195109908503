@import 'fonts/fonts.css';
@import 'winamp.css';

/* MSDOS 6.22 CSS Theme */
[data-theme="msdos"] {
    --charH:       19px;
    --charW1:       8px;
    --charW2:      16px;
    --charW2neg:  -16px;
    --railH:        3px;
    --knobW:        8px;
    --knobH:       19px;
    --buttonHeight: var(--charH);
    --rowHeight:    var(--charH);

    --clickable:  #ff0;
    --active:     #800;
    --button:     #66d;
    --selected:   #00f;
    --focus:      #00f;
    --background: #008;
    --shadow:     #005;
    --neutral0:   #000;
    --neutral1:   #777;
    --neutral2:   #aaa;
    --neutral3:   #ccc;
    --neutral4:   #fff;
}

.grid { /* utility class for testing the character grid */
    background-image:
        repeating-linear-gradient(
            to right, #fff1 0px, #fff1 1px, #fff0 1px, #fff0 var(--charW1)),
        repeating-linear-gradient(
            to bottom, #fff2 0px, #fff2 1px, #fff0 1px, #fff0 var(--charH));
}

.App * {
    font: var(--font);
    -webkit-font-smoothing: antialiased; /* supports Japanese characters better than 'none' */
    -moz-osx-font-smoothing: grayscale; /* Firefox */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    text-decoration: none;
}

[data-theme="msdos"] {
    --font: var(--fontPxPlusChipPlayer);

    --message-box-background: var(--button);
    --message-box-color: var(--clickable);
    --message-box-info-text-color: var(--clickable);
    --message-box-inner-border: 1px solid var(--clickable);
    --message-box-box-shadow: var(--charW1) var(--charW1) var(--shadow);
    --msdos-box-shadow: var(--charW1) var(--charW1) var(--shadow);
    --hide-mtime-column-below-width: 800px;

    /* chunky DOS animation */
    .message-box { transition: all 250ms steps(5); }
    .toast-box-outer { transition: all 150ms steps(4); }

    *::selection {
        background: rgba(170, 170, 170, 0.99);
        color: var(--background);
    }

    a:active { background: var(--active); }
}

[data-theme="msdos"] a:focus,
[data-theme="msdos"] button:focus,
[data-theme="msdos"] select:focus,
[data-theme="msdos"] input:focus,
[data-theme="msdos"] input[type=checkbox]:focus,
[data-theme="msdos"] input[type=radio]:focus,
[data-theme="msdos"] input[type=range]:focus {
    outline: none;
    background-color: var(--focus);
}

input,
button,
select,
textarea {
    font: inherit;
}

/* Basic elements */

body {
    background: var(--background);
    overflow: hidden;
    color: var(--neutral2);
}

/* Margins in one direction (bottom) */
h1, h2, h3, h4, h5, h6 {
    color: var(--neutral4);
    margin: 0 0 var(--charH) 0;
    padding: 0;
    font-weight: normal;
}

p { margin: 0 0 var(--charH) 0; }

a {
    text-decoration: none;
    color: var(--clickable);
    cursor: pointer;
}

a:visited { color: var(--clickable); }

[data-theme="msdos"] {

    /* Form elements */

button {
    border: none;
    border-radius: 0;
    padding: 0;
    text-wrap: nowrap;
    align-self: start;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: var(--clickable);
}
select {
    border: none;
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: 0 var(--charW1);
    color: var(--clickable);
    background-color: var(--button);
    background-image: /* A little down-arrow */
            linear-gradient(60deg, transparent 50%, var(--clickable) 50%),
            linear-gradient(120deg, var(--clickable) 50%, transparent 50%);
    background-position: calc(100% - var(--charW1)*1.5) 5px, calc(100% - var(--charW1)) 5px;
    background-size: calc(var(--charW1)*0.5) 12px, calc(var(--charW1)*0.5) 12px;
    background-repeat: no-repeat;
    padding-right: var(--charW2);
    max-width: 224px;
}
select option                { font-size: 14px; }
.Song-now-playing .FavoriteButton:not(.isFavorite) { color: var(--background); }
  @media screen and (max-width: 800px) { .BrowseList-colMtime { display: none } }
  @container (max-width: 800px) { .BrowseList-colMtime { display: none } }
button:active   { background: var(--active); }
button:disabled { background: var(--neutral1); color: var(--neutral3); }
.box-button     { background: var(--button); box-shadow: var(--msdos-box-shadow); white-space: pre; padding: 0 var(--charW2); }
.box-button:active { box-shadow: none; }

    .tab {
        padding: 0 15px 0 15px;
        background: transparent;
        border: 1px solid var(--clickable);
        border-bottom: none;
        border-radius: 3px 3px 0 0;
        box-sizing: border-box;
        height: var(--charH);
    }

    .tab-selected {
        border-bottom: 1px solid var(--background);
        background: var(--background);
        margin-bottom: -1px;
        height: calc(var(--charH) + 1px);
    }
    input[type=checkbox],
    input[type=radio] {
        appearance: none;
        background: transparent;
        border: 0;
        border-radius: 0;
        margin: 0 var(--charW1) 0 0;
        color: var(--clickable);
        white-space: pre;  /* prevent iOS Safari text wrapping */
    }

    input[type=checkbox]:before         { content: '[ ]'; }
    input[type=checkbox]:checked:before { content: '[️x]'; }

    input[type=radio]:before            { content: '( )'; }
    input[type=radio]:checked:before    { content: '(•)'; }
  input[type=text]::selection                 { background-color: #FFFFFF; }
  input[type=text]::-webkit-input-placeholder { color: var(--neutral1) }
}

[data-theme="msdos"] input[type=text] {
    border: 0;
    border-radius: 0;
    background-color: var(--neutral2);
    color: var(--background);
    padding: 0 var(--charW1);
    width: 256px;
    height: var(--charH); /* Firefox */
}

.inline { display: inline-block; margin-right: var(--charW1);   }
.inline:last-child { margin-right: 0; }

input[type=range] {
    margin: 0;
    -webkit-appearance: none;
    background: transparent;
    vertical-align: bottom;
    height: var(--charH); /* Firefox */
    width: 128px; /* Firefox */
}
input[type=range]::-webkit-slider-runnable-track {
    height: var(--railH);
    border: 1px solid var(--clickable);
    margin: var(--charW1) 0;
}
input[type=range]::-moz-range-track { /* Firefox */
    height: var(--railH);
    border: 1px solid var(--clickable);
    margin: var(--charW1) 0;
    box-sizing: border-box;
}

input[type=range]:disabled::-webkit-slider-runnable-track { border-color: var(--neutral2); }
input[type=range]:disabled::-moz-range-track { border-color: var(--neutral2); } /* Firefox */
input[type=range]:disabled::-webkit-slider-thumb { background: var(--neutral2); }
input[type=range]:disabled::-moz-range-thumb { background: var(--neutral2); } /* Firefox */
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: var(--clickable);
    height: var(--knobH);
    margin-top: calc(var(--knobH) / -2);
    width: var(--knobW);
}
input[type=range]::-moz-range-thumb { /* Firefox */
    appearance: none;
    background: var(--clickable);
    border: 0;
    border-radius: 0;
    height: var(--knobH);
    margin-top: calc(var(--knobH) / -2);
    width: var(--knobW);
}

/* Component overrides */

body,#root {
    margin: 0;
}

.App {
    padding: 0 var(--charW2);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-variant-numeric: tabular-nums;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.AppHeader {
    padding: var(--charH) var(--charW2);
    margin: 0 var(--charW2neg);
    flex-grow: 0;
}

.AppHeader-subtitle {
    margin: var(--charH) 0 0 0;
}

.AppHeader-title, .AppHeader-title:visited, .AppHeader-title:link {
    color: var(--neutral4);
}

.AppHeader-byline {
    float: right;
}

.App-main {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 100%;
    margin: 0 var(--charW2neg);
    margin-top: -1px; /* shrink by 1px for top border of tabs */
}

.App-main-inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 1px; /* prevents content width priority */
    margin-right: -1px; /* hide the right border of the content area */
}

.App-main-content-and-settings {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    flex-grow: 1;
}

.App-main-content-area {
    flex-grow: 1;
    /*display: flex;*/
    flex-direction: column;
    min-width: 0;
    padding: var(--charH) var(--charW2) 0 var(--charW2);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    border-right: 1px solid var(--clickable);
    container-type: size;
}
.App-main-content-area:focus { /* I have no idea why this became focusable */
    background-color: initial;
}

.ReactVirtualized__Grid:focus {
    outline: none;
}

.App-main-content-area.settings {
    width: 360px;
    min-width: 360px;
    flex-grow: 0;
}

.AppFooter {
    border-top: 1px solid var(--clickable);
    margin: 0 var(--charW2neg);
    flex-shrink: 0;
    height: 210px;
    display: flex;
}

.AppFooter-art {
    width: 210px;
    height: 210px;
    background-color: var(--neutral0);
    object-fit: scale-down;
    image-rendering: unset;
    border-left: 1px solid var(--clickable);
    flex-shrink: 0;
}

[data-theme="msdos"] {
    @media screen and (max-width: 600px) {
        .AppFooter-art, .AppFooter-shuffle, .AppFooter-repeat { display: none; }
    }
}

button.box-button.AppFooter-forward,
button.box-button.AppFooter-back { padding: 0 var(--charW1); }

.AppFooter-main {
    flex-grow: 1;
    overflow: auto;
    padding: var(--charH) var(--charW2);
    box-sizing: border-box;
    container-type: size;
}

.AppFooter-settings {
    border-left: 1px solid var(--clickable);
    padding: var(--charH) var(--charW2);
    max-width: 360px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.App-voice-label {
    width: calc(var(--charW1) * 14);
    white-space: nowrap;
    overflow-x: hidden;
}

.AppFooter-top-row {
    display: flex;
    align-items: center;
    gap: var(--charW1);
    white-space: nowrap;
}

.Slider {
    height: var(--charH);
    padding: 0;
    cursor: pointer;
    position: relative;
    width: calc(100% - var(--knobW));
    box-sizing: border-box;
    border: 0;
}

.Slider-rail {
    width: calc(100% + var(--knobW));
    height: var(--railH);
    background: none;
    position: absolute;
    top: calc((var(--charH) - var(--railH)) / 2);
    box-sizing: border-box;
    border: 1px solid var(--clickable);
}

.Slider-knob {
    height: var(--knobH);
    width: var(--knobW);
    top: calc((var(--charH) - var(--knobH)) / 2);
    margin: 0;
    border-radius: 0px;
    background-color: var(--clickable);
    position: absolute;
    -webkit-box-shadow: none;
    box-shadow: none;
    box-sizing: border-box;
}

.Browse-topRow {
    display: flex; justify-content: space-between; margin-bottom: var(--charH); gap: var(--charW2);
    min-height: var(--buttonHeight);
}

.BrowseList-row { display: flex; align-items: center; height: var(--rowHeight); }
/* Selected */
.ReactVirtualized__Grid:focus .BrowseList-row-selected {
    background-color:  var(--selected);
}
/* Playing */
.App-main .App-main-content-area .Song-now-playing {
    background-color: var(--button);
    color: var(--neutral3);
}
[data-theme="msdos"] {
/* Selected AND Playing */
.ReactVirtualized__Grid:focus .BrowseList-row-selected.Song-now-playing {
    box-shadow: 0 0 1px 1px inset var(--selected);
    outline: 1px solid var(--selected);
}
.BrowseList-row:has(a:hover) { color: var(--neutral4); }
 }
.BrowseList-row a { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
.BrowseList-colName { overflow: hidden; flex-grow: 1; display: flex; align-items: center; }
.BrowseList-colName a { white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
.BrowseList-colDir, .BrowseList-colSize { flex-shrink : 0; margin-left: calc(var(--charW1) * 2); }
.BrowseList-colCount { flex-shrink : 0; margin-left: var(--charW1); width: calc(var(--charW1) * 5); text-align: right; }
.BrowseList-colMtime { text-align: right; text-transform: uppercase; white-space: nowrap; min-width: calc(var(--charW1) * 12); }
.BrowseList-colSize { text-align: right; text-transform: uppercase; white-space: nowrap; min-width: calc(var(--charW1) * 10); }

.DirectoryLink-dim { color: var(--neutral2); overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.PlayerParams h3 { margin-top: 0; }
.PlayerParams-param { display: flex; flex-wrap: wrap; align-items: center; }
.PlayerParams-param input { margin-right: var(--charW1); flex-shrink: 0; }
.PlayerParams-group-title { width: 100%; }
.PlayerParams-voiceList {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--charW1);
    margin-right: calc(var(--charW1) * -1);
    max-width: calc(var(--charW1) * 60);
}
.PlayerParams-label { flex-shrink: 0; width: calc(var(--charW1) * 14); margin-right: var(--charW1); }
.PlayerParams-group { margin-bottom: var(--charH); }

.SongDetails {
    display: grid;
    grid-template-areas:
        'fav title'
        '... subtitle'
        '... path';
    grid-template-columns: auto 1fr;
}
.SongDetails-title { grid-area: title; color: var(--neutral4); display: flex; gap: var(--charW1); }
.SongDetails-title a { color: var(--neutral4); }
.SongDetails-subtitle { grid-area: subtitle; }
.SongDetails-filepath { grid-area: path; }
.SongDetails-subtitle, .SongDetails-subtitle, .SongDetails-filepath {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}
.SongDetails-filepath a {color: var(--neutral2);}

.Search-label { display: flex; align-items: center; gap: var(--charW1); }
.Search-input { width: calc(var(--charW1) * 20); }
.Search-clearButton { white-space: pre; background: var(--button); box-shadow: none; }
.Search-clearButton::before { content: ' x'; }
.Search-clearButton::after  { content: ' '; }
.Search-resultsLabel { white-space: nowrap; }

button.FavoriteButton:active { color: var(--clickable); }
button.FavoriteButton.isFavorite { color: var(--clickable); }
button.FavoriteButton {
    cursor: pointer;
    color: var(--button);
    background: initial;
    padding: 0 var(--charW1);
}

/*.Song-now-playing .Trash-button { background: var(--button); }*/
.Trash-button {
    cursor: pointer;
    background: initial;
}

.Visualizer { position: relative; display: flex; flex-direction: column; align-items: flex-end }
.Visualizer-overlay { position: absolute; top: calc(4 * var(--charH)); }
.Visualizer-options { padding: var(--charH) var(--charW2) 0; text-align: right; }
.Visualizer-toggle { position: fixed; top: var(--charH); right: var(--charW2); }
@media screen and (max-width: 600px) { .Visualizer-toggle { display: none; } }
.Visualizer-analyzer { display: block; image-rendering: pixelated; }
.Visualizer-spectrogram { display: block; flex-grow: 1; flex-shrink: 0; min-height: 800px; image-rendering: pixelated; }
.Visualizer-analyzer, .Visualizer-spectrogram {
    margin-left: 1px; /* Don't cover the right border of App-main-inner */
}

.TimeSlider { flex-grow: 1; margin: var(--charH) 0; }
.TimeSlider-labels, .VolumeSlider-labels { display: flex; justify-content: space-between; gap: var(--charW2); }

.VolumeSlider { flex-shrink: 0; margin: var(--charH) 0; }

[data-theme="msdos"] {
    @media screen and (max-width: 600px) {
        .VolumeSlider { display: none; }
    }
}

.inline-icon {
    vertical-align: bottom;
    display: inline-block;
    background-color: var(--clickable);
    mask-repeat: no-repeat;
    mask-position: center;
    /* the icon PNGs are 19px tall, so the container needs odd height for vertical centering. */
    height: calc(round(up, var(--charH), 2px) - 1px);
    flex-shrink: 0;
}
[disabled] .inline-icon { background-color: var(--neutral2); }
.dim-icon      { background-color: var(--neutral2); }
.icon-play     { width: 24px; mask-image: url(images/play.png); }
.icon-pause    { width: 24px; mask-image: url(images/pause.png); }
.icon-prev     { width: 24px; mask-image: url(images/prev.png); }
.icon-next     { width: 24px; mask-image: url(images/next.png); }
.icon-back     { width: 24px; mask-image: url(images/back.png); }
.icon-forward  { width: 24px; mask-image: url(images/forward.png); }
.icon-folder   { width: 24px; mask-image: url(images/folder.png); }
.icon-trash    { width: 24px; mask-image: url(images/trash.png); }
.icon-copy     { width: 24px; mask-image: url(images/copy.png); }
.icon-download { width: 16px; mask-image: url(images/download.png); }
.icon-repeat   { width: 16px; mask-image: url(images/repeat.png); }
.icon-shuffle  { width: 16px; mask-image: url(images/shuffle.png); }
.icon-chip     { width: 24px; mask-image: url(images/chip.png); }

.tab-container {
    border-bottom: 1px solid var(--clickable);
    padding: 0 var(--charW2);
    display: flex;
    gap: var(--charW1);
}

.tab-settings { margin-left: auto; margin-right: 0; }

.toast-box-outer {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: start;
    justify-content: center;
    height: 100%;
    width: 100%;
    pointer-events: none;
    top: 0;
    left: 0;
    padding: 0 var(--charW2);
    box-sizing: border-box;
}
.toast-box {
    padding: var(--charH) var(--charW2);
    box-shadow: var(--msdos-box-shadow);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: start;
    overflow: hidden;
    max-height: 100%;
    pointer-events: all;
    width: 100%;
    height: calc(4 * var(--charH));
}
.toast-box-outer[hidden] {
    top: calc(-4 * var(--charH));
    visibility: hidden;
}
.toast-box .message {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    max-height: 100%;
    overflow: hidden;
    padding-right: var(--charW1);
    text-overflow: ellipsis;
    white-space: initial;
}
.toast-error {
    background-color: var(--active);
    color: var(--neutral4);
}
.toast-info {
    background-color: var(--button);
    color: var(--neutral3);
}

/* message-box is used by Info box and DropMessage component. */
.message-box-outer {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: round(calc(100% + 0px), 2px);;
    pointer-events: none;
    top: 0;
    left: 0;
    padding: var(--charH) var(--charW2);
    box-sizing: border-box;
}

.message-box-outer[hidden] {
    visibility: hidden;
}

.message-box {
    background: var(--message-box-background);
    padding: var(--charH) var(--charW2);
    box-shadow: var(--message-box-box-shadow);
    outline: var(--message-box-border);
    box-sizing: border-box;
    display: flex;
    visibility: visible;
    overflow: hidden;
    max-height: 100%;
    pointer-events: all;
    flex-direction: column;
    width: calc(90 * var(--charW1));
    height: calc(40 * var(--charH));
}

.message-box[hidden] {
    width: 0;
    height: 0;
    visibility: hidden;
}

.message-box[hidden] .message-box-inner {
    overflow: hidden;
}

.message-box-inner {
    color: var(--message-box-color);
    outline: var(--message-box-inner-border);
    padding: var(--charH) var(--charW2);
    overflow: auto;
}

.message-box-inner.info-text {
    white-space: pre;
    font: var(--message-box-info-text-font);
    color: var(--message-box-info-text-color);
    background: var(--message-box-info-text-background);
}

.message-box-footer {
    display: flex;
    flex-direction: row-reverse;
    align-content: end;
    justify-content: space-between;
    padding-top: var(--charH);
}

.drop-message {
    height: calc(9 * var(--charH) + 1px);
    width: calc(60 * var(--charW1));
    text-align: center;
}

.drop-message .message-box-inner {
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}


[data-theme="msdos"] .message-box-button {
    background: var(--background);
    padding: 0 var(--charW2);
    box-shadow: none;
}

@media (max-resolution: 1dppx) {
  /* ensure crisp icons at 1:1 native resolution, smooth images otherwise */
  img, .Visualizer-overlay, .Visualizer-analyzer, .Visualizer-spectrogram, .inline-icon {
      image-rendering: pixelated;
  }
}

/* Scrollbar styling */
[data-theme="msdos"] ::-webkit-scrollbar {
    height: var(--charW1);
    width: var(--charW1);
    background-color: var(--button);
}

[data-theme="msdos"] ::-webkit-scrollbar-thumb {
    background-color: var(--clickable);
    -webkit-border-radius: 0;
}
[data-theme="msdos"] ::-webkit-scrollbar-corner {
    background-color: var(--neutral2);
}
